import Plyr from 'plyr'

import 'plyr/dist/plyr.css'

export default () => {
  return {
    player: undefined,
    played: false,
    playing: false,
    initVideo() {
      this.player = new Plyr(this.$refs.videoContainer, {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          // 'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
      })

      this.player.on('ready', (event) => {
        this.player.play()
      })
    },
    playButton: {
      ['@click']() {
        if (!this.played) {
          this.played = true
          this.initVideo()
        } else {
          this.player.togglePlay()
          this.playing = !this.playing
        }

        this.$el.style.display = 'none'
      },
    },
    playIcon: {
      [':class']() {
        return this.playing || 'playing'
      },
    },
    image: {
      [':class']() {
        return this.played && 'played'
      },
    },
  }
}
