export default () => {
  return {
    scroll: Boolean,
    scrollDirection: undefined,
    init() {
      this.watchScroll()
      this.fontsLoaded()
    },
    fontsLoaded() {
      let interval = null

      function fontLoadListener() {
        if (
          document.fonts.check('12px mongoose') === true &&
          document.fonts.check('12px raleway') === true
        ) {
          fontLoadedSuccess()
          return true
        } else {
          return false
        }
      }

      function fontLoadedSuccess() {
        if (interval) {
          clearInterval(interval)
        }
        document.body.classList.add('fonts-ready')
      }

      interval = setInterval(fontLoadListener, 500)
    },
    watchScroll() {
      // watch for scroll
      this.scrollPosition = window.pageYOffset || window.scrollTop
      window.addEventListener('scroll', () => {
        if (window.pageYOffset >= this.scrollPosition) {
          this.scrollDirection = 'down'
        } else if (window.pageYOffset <= this.scrollPosition) {
          this.scrollDirection = 'up'
        } else if (window.pageYOffset == 0) {
          this.scrollDirection = 'top'
        }

        this.scroll = true
        this.scrollPosition = window.pageYOffset

        document.body.dataset.scroll = this.scrollDirection
      })
    },
  }
}
