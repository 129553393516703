import Alpine from 'alpinejs'
import globalStore from '@/js/store'
import components from '@/js/alpine'

import '@/styles/main.scss'

if (!window.acf) {
  window.Alpine = Alpine

  Alpine.store('global', globalStore())

  const registerComponent = async (component) => {
    let componentSlug = component.replace(/-./g, (x) => x[1].toUpperCase())

    if (document.querySelectorAll(`[x-data^=${componentSlug}]`).length > 0) {
      new URL(`./alpine/${component}.js`, import.meta.url)

      await import(`./alpine/${component}.js`)
        .then((module) => {
          // console.log(component)
          Alpine.data(componentSlug, module.default)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const registerComponents = async () => {
    for (const component of components) {
      await registerComponent(component)
    }
    Alpine.start()
  }

  ;(async () => {
    await registerComponents()
  })()
}

// load defered videos
window.videosReady = false

document.onreadystatechange = function (event) {
  if (document.readyState === 'complete') {
    const videos = document.querySelectorAll('[data-src-defer]')
    const videos_array = [...videos]
    videos_array.forEach((video) => {
      video.setAttribute('src', video.getAttribute('data-src-defer'))
    })
    window.videosReady = true
  }
}

// Alert bar functionality
const formData = new FormData();

formData.append('action', 'get_alert_bar');
formData.append('post_id', wp_config.post_id);

// fetch alert bar
fetch(wp_config.ajaxurl, {
  method: 'POST',
  body: formData,
  cache: 'no-cache',
  headers: {
    page_cache_maximum_age: 0,
  },
})
  .then((response) => response.json())
  .then((data) => {
    if (data.enabled) {
      document.getElementById('alertBar').classList.add('is-active')
      document.getElementById('alertBar').innerHTML = `
          <section class="alert-bar alert-bar--${data.background_color}">
              <div class="alert-bar__inner">
                  <div class="alert-bar__text">${data.message}</div>
              </div>
          </section>
      `
    }
  })

  class FormAssemblyForms {
    constructor() {
      this.forms = document.querySelectorAll('form[action*="lvc.tfaforms.net"]');
  
      if (this.forms.length > 0) {
        this.listen();
      }
    }
  
    listen() {
      this.forms.forEach((form) => {
          form.addEventListener('form.validation.formassembly', (e) => {
              if ("passed" === e.detail.statusText) {
                  dataLayer.push({
                      event: "formassembly_submit",
                      form_id: form.id
                  });
  
                  console.groupCollapsed('FormAssembly debug data: Form submitted');
                  console.log('form assembly submit');
                  console.log(e.detail);
                  console.log(form.id);
                  console.groupEnd();
              } else {
                console.groupCollapsed('FormAssembly debug data: Form validation failed');
                console.log('form assembly submit');
                console.log(e.detail);
                console.log(form.id);
                console.groupEnd();
              }
          })
      });
    }
  } 

  const formAssemblyForms = new FormAssemblyForms();