export default () => {
  return {
    selected: '',
    tabButtons: [],
    tabButtonFocusIndex: 1,
    init() {
      this.tabButtons =
        this.$refs.tabButtonsList.querySelectorAll('[role="tab"]')

      const firstTabID = this.tabButtons[0]
        .getAttribute('id')
        .replace('tab-button-', '')

      this.selected = firstTabID
    },
    changeButtonFocus(increment) {
      if (increment == 1) {
        if (this.tabButtonFocusIndex < this.tabButtons.length) {
          this.tabButtonFocusIndex++
          this.setButtonFocus()
        }
      } else if (increment == -1) {
        if (this.tabButtonFocusIndex >= 2) {
          this.tabButtonFocusIndex--
          this.setButtonFocus()
        }
      }
    },
    setButtonFocus() {
      this.tabButtons[this.tabButtonFocusIndex - 1].focus()
    },
    get tabButtonWidth() {
      return 100 / this.tabButtons.length
    },
    get tabTotal() {
      return this.tabButtons.length
    },
    tabButton: {
      '@click'() {
        this.selected = this.id
      },
      '@keyup.right'() {
        this.changeButtonFocus(1)
      },
      '@keyup.left'() {
        this.changeButtonFocus(-1)
      },
      ':aria-selected'() {
        return this.id == this.selected
      },
    },
    tabPanel: {
      'x-show'() {
        return this.id == this.selected
      },
      ':tabindex'() {
        return this.id !== this.selected ? -1 : 0
      },
    },
    tabIndicator: {
      ':id'() {
        return this.selected
      },
      ':style'() {
        return {
          width: this.tabButtonWidth + '%',
          transform: `translateX(${
            this.tabButtonWidth * (this.selected * this.tabTotal)
          }%)`,
        }
      },
    },
  }
}
