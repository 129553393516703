import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

import accordion from './accordion.js'

Alpine.data('accordion', accordion)

Alpine.plugin(focus)

export default (
  undergradRequestInfoLink,
  undergradApplyNowLink,
  gradRequestInfoLink,
  gradApplyNowLink,
  undergradSearchLabel,
  gradSearchLabel
) => {
  return {
    search: String,
    prevSearch: undefined,
    results: [],
    selectedProgram: {},
    showResults: false,
    programLevel: 'undergraduate',
    prevProgramLevel: undefined,
    init() {
      this.$watch('programLevel', () => this.searchPrograms())

      this.stateChangeWatch()
    },
    stateChangeWatch() {
      if (history.state !== null) {
        this.$dispatch('togglePanel', {
          panel: 'top',
          id: 'global-programs',
          focusTrap: false,
        })

        this.$dispatch('togglePanel', {
          panel: 'programs-results',
          id: 'global-programs-results',
          focusTrap: false,
        })

        this.selectedProgram = JSON.parse(history.state.selectedProgram)
      } else if (location.search.search('programfinder') > 0) {
        this.$dispatch('togglePanel', {
          panel: 'top',
          id: 'global-programs',
          focusTrap: false,
        })

        this.$dispatch('togglePanel', {
          panel: 'programs-results',
          id: 'global-programs-results',
          focusTrap: false,
        })

        const params = new URLSearchParams(window.location.search)
        const programSlug = params.get('programfinder')

        this.getProgramDataFromSlug(programSlug)
      }
    },
    getProgramDataFromSlug(programSlug) {
      fetch(`/wp-json/wp/v2/programs?slug=${programSlug}`)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          let programData = {
            title: data[0].title.rendered,
            description: data[0].acf.intro,
            image: data[0]?.acf?.image?.sizes?.medium_large,
            url: data[0].link,
            programLevel: data[0].programLevel,
            related: data[0].related_programs,
            slug: data[0].slug,
          }
          this.selectedProgram = programData
        })
    },
    searchPrograms() {
      if (
        this.search === this.prevSearch &&
        this.programLevel === this.prevProgramLevel
      ) {
        // console.log('duplicate stopped!')
        return
      }

      this.prevSearch = this.search
      this.prevProgramLevel = this.programLevel

      if (this.search.length > 2) {
        fetch(
          `/wp-json/program-search/v1/posts/?query=${encodeURIComponent(
            this.search
          )}&program_level=${this.programLevel}`
        )
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            this.showResults = true
            if (!data.length) {
              // console.log(this.search, '!data')
              this.results = []

              // console.log({
              //   query: this.search,
              //   programLevel: this.programLevel,
              //   results: 'none',
              // })

              dataLayer.push({
                event: 'programSearch',
                query: this.search,
                programLevel: this.programLevel,
                results: 'none',
              })
            } else {
              this.results = data

              const foundIdx = this.results.findIndex(
                (el) =>
                  el.title.rendered.toLowerCase() == this.search.toLowerCase()
              )
              console.log(foundIdx)
              if (foundIdx > 0) {
                const match = this.results[foundIdx]
                this.results.splice(foundIdx, 1)
                this.results.unshift(match)
              }

              // console.log({
              //   query: this.search,
              //   programLevel: this.programLevel,
              //   results: this.results.map((result) => result.slug),
              // })

              dataLayer.push({
                event: 'programSearch',
                query: this.search,
                programLevel: this.programLevel,
                results: this.results.map((result) => result.slug),
              })
            }
          })
      } else {
        // console.log('else')
        this.results = []
        this.showResults = false
      }
    },
    htmlDecode(input) {
      var doc = new DOMParser().parseFromString(input, 'text/html')

      return doc.documentElement.textContent
    },
    programSearch: {
      'x-trap.noscroll.inert'() {
        return this.showResults
      },
      '@keyup.escape.stop'() {
        this.showResults = false
      },
    },
    programSearchInput: {
      '@keyup.debounce'(e) {
        e.preventDefault()
        if (e.key !== 'Escape') {
          this.search = this.$refs.programSearchInput.value
          this.searchPrograms()
        }
      },
    },
    programSearchResults: {
      'x-show'() {
        return this.showResults
      },
    },
    programSearchResultsNone: {
      'x-show'() {
        return this.search.length > 2 && this.results.length === 0
      },
    },
    programSearchResultButton: {
      '@click'(e) {
        let programData = {
          title: this.title.rendered,
          description: this.acf.intro,
          image: this?.acf?.image?.sizes?.medium_large,
          url: this.link,
          programLevel: this.programLevel,
          related: this.related_programs,
          slug: this.slug,
        }

        this.selectedProgram = programData

        // console.log(programData)

        dataLayer.push({
          event: 'programSelect',
          query: this.search,
          programLevel: this.programLevel,
          results: this.results.map((result) => result.slug),
          selectedProgram: programData.slug,
        })

        this.$dispatch('togglePanel', {
          panel: 'programs-results',
          id: 'global-programs-results',
          focusTrap: false,
        })

        this.showResults = false
        this.results = []

        history.pushState(
          { selectedProgram: JSON.stringify(programData) },
          '',
          '?programfinder=' + this.selectedProgram.slug
        )
      },
    },
    programResults: {
      'x-if'() {
        return Object.keys(this.selectedProgram).length > 0
      },
    },
    programResultImage: {
      ':src'() {
        return this.selectedProgram.image
      },
      ':alt'() {
        return `image for ${this.selectedProgram.title}`
      },
    },
    programResultLink: {
      ':href'() {
        return this.selectedProgram.url
      },
    },
    requestInfoLink: {
      'x-init'() {
        const newTitle =
          this.selectedProgram.programLevel === 'undergraduate'
            ? undergradRequestInfoLink.title
            : gradRequestInfoLink.title
        this.$el.querySelector('.base-button__text').innerText = newTitle
      },
      ':href'() {
        return this.selectedProgram.programLevel === 'undergraduate'
          ? undergradRequestInfoLink.url
          : gradRequestInfoLink.url
      },
      ':target'() {
        return this.selectedProgram.programLevel === 'undergraduate'
          ? undergradRequestInfoLink.target
            ? undergradRequestInfoLink.target
            : '_self'
          : gradRequestInfoLink.target
          ? gradRequestInfoLink.target
          : '_self'
      },
    },
    applyNowLink: {
      'x-init'() {
        const newTitle =
          this.selectedProgram.programLevel === 'undergraduate'
            ? undergradApplyNowLink.title
            : gradApplyNowLink.title
        this.$el.querySelector('.base-button__text').innerText = newTitle
      },
      ':href'() {
        return this.selectedProgram.programLevel === 'undergraduate'
          ? undergradApplyNowLink.url
          : gradApplyNowLink.url
      },
      ':target'() {
        return this.selectedProgram.programLevel === 'undergraduate'
          ? undergradApplyNowLink.target
            ? undergradApplyNowLink.target
            : '_self'
          : gradApplyNowLink.target
          ? gradApplyNowLink.target
          : '_self'
      },
    },
    programsOverlay: {
      ':class'() {
        return {
          'global-header__programs-overlay--open':
            Object.keys(this.selectedProgram).length > 0,
        }
      },
    },
    relatedProgramItems: {
      'x-for': 'item in selectedProgram.related',
    },
  }
}
