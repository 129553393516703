import Alpine from 'alpinejs'
import Swiper, { Navigation, Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default (type = 'news') => {
  let extraArgs = {}
  let extraBreakpointArgs = {}

  if (type === 'news') {
    extraArgs = {}
    extraBreakpointArgs = {}
  } else if (type === 'date') {
    extraArgs = {}
    extraBreakpointArgs = {}
  }

  return {
    init() {
      const swiper = new Swiper(this.$el, {
        modules: [Navigation, Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 10,
        breakpoints: {
          ...extraBreakpointArgs,
          1024: {
            enabled: false,
          },
        },
        ...extraArgs,
      })
    },
  }
}
