import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)

export default () => {
  return {
    open: false,
    init() {
      // watch for end of menu transition
      this.$refs.sectionMenuToggleArea.addEventListener('transitionend', () => {
        this.onMenuToggleEnd()
      })

      // watch for open status
      this.$watch('open', (value) => this.watchOpenStatus(value))
    },
    watchOpenStatus(value) {
      // only change status on mobile
      if (window.innerWidth < 768) {
        if (value === true) {
          this.setMenuHeight('full')
        } else if (value === false) {
          this.setMenuHeight('full')
          setTimeout(() => {
            this.setMenuHeight('0px')
          }, 0)
        }
      } 
    },
    setMenuHeight(newHeight) {
      let menuArea = this.$refs.sectionMenuToggleArea
      newHeight = newHeight == 'full' ? menuArea.scrollHeight + 'px' : newHeight
      menuArea.style.height = newHeight
    },
    onMenuToggleEnd() {
      let setHeight = this.open ? 'auto' : ''
      this.setMenuHeight(setHeight)
    },
    sectionMenuButton: {
      '@click'() {
        this.open = !this.open
      },
      ':aria-expanded'() {
        return this.open
      },
    },
  }
}
