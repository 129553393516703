export default () => {
  return {
    liveLearnArray: [
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
      Math.round(Math.random()) ? 'live' : 'learn',
    ],
    slider: 1,
    get cardsType() {
      switch (parseInt(this.slider)) {
        case 0:
          return 'live'
        case 1:
          return 'mixed'
        case 2:
          return 'learn'
      }
    },
    shouldShow(index, type) {
      if (this.cardsType == 'mixed') {
        return this.liveLearnArray[index] == type
      }

      return this.cardsType == type
    },
  }
}
