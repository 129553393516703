import Alpine from 'alpinejs'
import Plyr from 'plyr'

import 'plyr/dist/plyr.css'

export default () => {
  return {
    player: undefined,
    initVideo() {
      this.player = new Plyr(this.$refs.video, {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          // 'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
      })

      this.player.on('ready', (event) => {
        this.player.play()
      })
    },
    playButton: {
      ['@click']() {
        this.initVideo()
        this.$refs.container.classList.add('callout-two-columns--play')
        this.$el.style.display = 'none'
      },
    },
  }
}
