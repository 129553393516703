import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

Alpine.plugin(focus)
Alpine.plugin(intersect)

export default () => {
  return {
    globalHeaderHeight: Number,
    globalHeaderToggle: false,
    init() {
      this.setGlobalHeaderHeight()
    },
    openPanels: {},
    currentPanelFocus: String,
    globalHeader: {
      '@togglePanel.window'() {
        if (this.callback !== undefined) {
          this.callback()
        }
        this.openPanels[this.$event.detail.panel] = this.$event.detail.id
      },
      ':class'() {
        return {
          'global-header--open': this.globalHeaderToggle,
        }
      },
      '@keyup.escape'() {
        this.closeGlobalHeader()
      },
    },
    globalButtonToggle: {
      '@click'() {
        if (this.callback !== undefined) {
          this.callback()
        }

        // watch for open/close of global header
        switch (this.openPanels[this.panel]) {
          case undefined:
            this.openPanels[this.panel] = this.id
            this.globalHeaderToggle = true
            break
          case this.id:
            this.closeGlobalHeader()
            break
          default:
            if (this.openPanels[this.panel] !== this.id) {
              if (this.id == '') {
                this.closeGlobalHeader()
              } else {
                this.openPanels[this.panel] = this.id
              }
            }
            break
        }
      },
      ':aria-controls'() {
        return this.id
      },
      ':aria-expanded'() {
        if (this.openPanels[this.panel] == this.id) {
          return true
        } else {
          return false
        }
      },
    },
    globalPanel: {
      'x-show'() {
        return this.openPanels[this.panel] == this.$el.id
      },
      'x-transition:enter': 'transition-enter',
      'x-transition:enter-start': 'transition-enter-start',
      'x-transition:leave': 'transition-leave',
      'x-transition:leave-end': 'transition-leave-end',
      'x-trap.inert.noscroll'() {
        return (
          this.openPanels[this.panel] == this.$el.id && this.focusTrap !== false
        )
      },
    },
    globalPanelBackground: {
      'x-show'() {
        return this.openPanels['programs-results'] == 'global-programs-results'
      },
    },
    scrollHolder: {
      ':style'() {
        return {
          height: this.globalHeaderHeight + 'px',
        }
      },
    },
    globalButtonClose: {
      '@click'() {
        this.closeGlobalHeader()
      },
    },
    setGlobalHeaderHeight() {
      const globalBannerHeight = this.$refs.globalBanner.offsetHeight || 0
      const sectionMenuHeight = this.$el.querySelector('.section-menu')
        ? this.$el.querySelector('.section-menu').offsetHeight
        : 0

      this.globalHeaderHeight = globalBannerHeight + sectionMenuHeight
    },
    closeGlobalHeader() {
      this.openPanels[this.panel] = undefined
      if (this.panel == 'top') {
        this.globalHeaderToggle = false
      }
    },
  }
}
