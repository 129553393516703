export default () => {
  return {
    open: false,
    readMoreToggle: {
      '@click'() {
        this.open = true
      },
      ':hidden'() {
        return this.open
      },
    },
    readMoreContent: {
      ':class'() {
        return this.open && 'program-hero__description--open'
      },
    },
  }
}
