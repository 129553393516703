export default () => {
  return {
    tables: undefined,
    init() {
      this.setupTables()
    },
    setupTables() {
      this.tables = this.$el.querySelectorAll('.wysiwyg__tableWrapper')
      if (this.tables.length > 0) {
        this.tables.forEach((table) => {
          // watch for table element resizing
          const tableResizer = new ResizeObserver(this.tableObserver)
          tableResizer.observe(table.querySelector('table'))

          // watch for table scroll
          table
            .querySelector('table')
            .addEventListener('scroll', this.tableScroller)
        })
      }
    },
    tableObserver(entries) {
      let wrapperWidth = entries[0].contentRect.width
      let tableWidth = entries[0].target.scrollWidth
      if (wrapperWidth < tableWidth) {
        entries[0].target.parentElement.classList.add(
          'wysiwyg__tableWrapper--scroll-active'
        )
      } else {
        entries[0].target.parentElement.classList.remove(
          'wysiwyg__tableWrapper--scroll-active'
        )
      }
    },
    tableScroller(event) {
      const scrollLeftLimit =
        event.target.scrollWidth - event.target.offsetWidth

      if (event.target.scrollLeft == scrollLeftLimit) {
        event.target.parentElement.classList.add(
          'wysiwyg__tableWrapper--scroll-end'
        )
      } else {
        event.target.parentElement.classList.remove(
          'wysiwyg__tableWrapper--scroll-end'
        )
      }
    },
  }
}
