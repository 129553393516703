import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)

export default () => {
  return {
    selected: null,
    accordionToggle: {
      ':aria-expanded'() {
        return this.selected == this.id
      },
      '@click'() {
        this.selected = this.selected !== this.id ? this.id : null
      },
    },
    accordionContent: {
      'x-show'() {
        return this.selected == this.id
      },
      'x-collapse': '',
    },
  }
}
