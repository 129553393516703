export default () => {
  return {
    open: false,
    ['x-on:keydown.escape.prevent.stop']() {
      return this.close(this.button)
    },
    ['x-on:focusin.window']() {
      return !this.panel.contains(this.$event.target) && this.close()
    },
    ['x-id']() {
      return ['dropdown-button']
    },
    toggle() {
      if (this.open) {
        return this.close()
      }

      this.$refs.button.focus()

      return (this.open = true)
    },
    close(focusAfter) {
      if (!this.open) return

      this.open = false

      focusAfter && focusAfter.focus()
    },

    button: {
      ['@click']() {
        return this.toggle()
      },
      [':aria-expanded']() {
        return this.open
      },
      [':aria-controls']() {
        return this.$id('dropdown-button')
      },
      [':class']() {
        return this.open && 'open'
      },
    },

    panel: {
      [':class']() {
        return this.open ? 'open' : ''
      },
      ['x-on']() {
        return (this.click.outside = this.close(this.button))
      },
      [':id']() {
        return this.$id('dropdown-button')
      },
    },
  }
}
