import _ from 'lodash'

export default (staticDegreeTypes = null) => {
  return {
    query: '',
    degreeType: 'all',
    areaOfStudy: 'all',
    init() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })

      this.query = params.query || ''
      this.degreeType = params.degree_type || 'all'
      this.areaOfStudy = params.area_of_study || 'all'

      if (this.getCookie(window.location.pathname)) {
        window.scroll(0, this.getCookie(window.location.pathname))
      }

      const callback = () =>
        this.setCookie(window.location.pathname, window.scrollY, 1)

      window.addEventListener('scroll', _.debounce(callback, 500))
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    },
    getCookie(cname) {
      let name = cname + '='
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    submit() {
      const query = document.getElementById('search')
      const degreeSelect = document?.getElementById('degree_type')
      const studySelect = document.getElementById('area_of_study')

      const queryValue = encodeURIComponent(query.value)
      const degreeSelectValue =
        degreeSelect?.options[degreeSelect?.selectedIndex].value || 'all'
      const studySelectValue =
        studySelect.options[studySelect.selectedIndex].value

      let queryUrl = `?area_of_study=${studySelectValue}`

      if (queryValue.length > 0) {
        queryUrl += `&query=${queryValue}`
      }

      if (degreeSelect) {
        queryUrl += `&degree_type=${degreeSelectValue}`
      }

      return (window.location.href = queryUrl)
    },
    search: {
      ['@keyup.enter']() {
        this.submit()
      },
    },
    submitButton: {
      ['@click']() {
        this.submit()
      },
    },
  }
}
