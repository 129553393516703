import Alpine from 'alpinejs'
import Swiper, { Navigation, Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default () => {
  return {
    init() {
      const swiper = new Swiper(this.$refs.swiper, {
        modules: [Navigation, Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 10,
        breakpoints: {
          768: {
            centeredSlides: false,
            navigation: {
              nextEl: '.cards-header__gallery-next',
              prevEl: '.cards-header__gallery-prev',
            },
          },
        },
      })
    },
  }
}
