import Swiper, { Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'

export default () => {
  return {
    init() {
      const swiper = new Swiper(this.$el, {
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        slidesPerView: 1,
        autoHeight: true,
        centeredSlides: true,
      })
    },
  }
}
