import Alpine from 'alpinejs'
import Plyr from 'plyr'

import 'plyr/dist/plyr.css'

export default () => {
  return {
    backgroundPlayer: undefined,
    backgroundPlaying: true,
    mainPlayer: undefined,
    init() {
      const newThis = this
      const interval = setInterval(function () {
        console.log(window.videosReady)

        if (window.videosReady) {
          newThis.initBackgroundVideo()
          clearInterval(interval)
        }
      }, 200)
    },
    initBackgroundVideo() {
      this.backgroundPlayer = new Plyr(this.$refs.backgroundVideo, {
        controls: [],
        autoplay: true,
        muted: true,
        loop: { active: true },
      })

      this.backgroundPlayer.on('ready', () => {
        this.backgroundPlayer.play()
      })
    },
    initMainVideo() {
      this.mainPlayer = new Plyr(this.$refs.mainVideo, {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          // 'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
      })

      this.mainPlayer.on('ready', () => {
        this.mainPlayer.play()
      })
    },
    pauseButton: {
      ['@click']() {
        this.backgroundPlayer.togglePlay()
        this.backgroundPlaying = !this.backgroundPlaying
      },
      ':class'() {
        return this.backgroundPlaying || 'fwvi__background-pause--paused'
      },
      ':aria-label'() {
        return this.backgroundPlaying
          ? 'Pause background video'
          : 'Play background video'
      },
    },
    playButton: {
      ['@click']() {
        this.initMainVideo()
        this.$refs.mainVideoContainer.classList.add('fwvi__main-video--played')
        this.backgroundPlayer.destroy()
      },
    },
  }
}
