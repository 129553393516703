// add alpine components to be registered to this array

export default [
  'accordion',
  'callout-video-player',
  'card-carousel',
  'testimonial-carousel',
  'full-width-video-player',
  'full-width-program-finder',
  'global-header',
  'global-programs',
  'live-learn',
  'inline-video',
  'tabs',
  'gallery',
  'wysiwyg',
  'dropdown',
  'program-hero',
  'section-menu',
]
